import React from 'react'
import Logo from '../assets/images/icon_trucktronix.png';
import { BsTwitter, BsYoutube } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import { Link } from 'react-scroll';
import { Link as Links } from "react-router-dom";
import './Footer.css'
function Footer() {
    const handleClickMenu = (item) => {
        // Scroll to the top of the content
        window.scrollTo(0, 0);
    };
    return (
        <div className='footer-container'>
            <div
                className='footer-wrapper'
            >
                <div className='footer-section-one'>
                    <div className='footer-logo-container'>
                        <img src={Logo} alt="Trucktronix" />
                    </div>
                    <p
                        className='secondary-text'
                    >
                        OneWeigh Scale Solutions is designed to optimize operations, maintain precision, and improve efficiency in
                        heavy equipment machinery industries.
                    </p>
                    <div className='footer-icons'>
                        <BsTwitter />
                        <BsYoutube />
                        <FaFacebookF />
                    </div>
                </div>
                <div className='footer-section-two'>
                    <div className='footer-section-columns'>
                        <p
                            // style={{
                            //     textTransform: 'none!important',
                            //     fontWeight: '600',
                            //     fontSize: 'clamp(1.5rem, 5vw, 2rem)',
                            //     marginLeft: '15%',
                            //     color:'#1e1919',

                            // }}
                            className='footer-header-tops'
                        >Quick Links</p>

                        <Link className='footer-links' to='about' smooth={true} duration={500}>
                            <span>About Us</span>
                        </Link>
                        <Link className='footer-links' to='contact' smooth={true} duration={500}>
                            <span>Contact Us</span>
                        </Link>
                        <Link className='footer-links' to='pricing' smooth={true} duration={500}>
                            <span>OneWeigh Scale Pricing</span>
                        </Link>
                        <Link className='footer-links' to='features' smooth={true} duration={500}>
                            <span>OneWeigh Features</span>
                        </Link>
                        <Link className='footer-links' smooth={true} duration={500}>
                            <span>Gallery</span>
                        </Link>

                        <Links to="/privacy-policy"
                            onClick={() => handleClickMenu()}
                            className='footer-links' smooth={true} duration={500}
                        >
                            <span>Meltrons Privacy Policy</span>
                        </Links>
                    </div>

                    <div className='footer-section-columns'>
                        <p
                            // style={{
                            //     textTransform: 'none!important',
                            //     fontWeight: '600',
                            //     marginLeft: '25%',
                            //     fontSize: 'clamp(1.5rem, 5vw, 2rem)',
                            //     color:'#1e1919',

                            // }}
                            className='footer-header-tops'>Contact Us</p>
                        <span className='footer-links-right' >Phone: 1300 342 147</span>
                        <span className='footer-links-right' >Email: sales@haulweigh.com</span>
                        {/* <span className='footer-links-right' 
                            style={{ marginLeft: '35%' }}
                        >
                            support@haulweigh.com</span> */}
                        <span className='footer-links-right' >Mail:
                            PO BOX 294, HORSLEY PARK, 2175, NSW, AUSTRALIA</span>
                        <span className='footer-links-right' >Trading Hours:
                            Mon to Fri From 9am to 5pm (AEST) </span>
                    </div>
                    {/* <div className='footer-section-columns'>
                <span>Terms & Conditions</span>
                <span>Terms & Conditions</span>

                </div> */}
                </div>

                {/* <small class="website-rights">©2023 TruckMaps. All rights reserved.</small> */}
                {/* <br/><br/>
                <div class="website-rights">
                <small >©2023 OneWeigh. All rights reserved.</small>
            </div> */}
            </div>
            <div >
                <small class="website-rights">©2023 OneWeigh. All rights reserved.</small>
            </div>
        </div>
    )
}

export default Footer