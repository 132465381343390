

// import React, { useRef } from 'react'
// import { useGLTF } from '@react-three/drei'

// export function Model(props) {
//   const { nodes, materials } = useGLTF('vt7/vtseven.gltf')
//   const meshRef = useRef();
//   // Set the scale of the mesh
//   //  meshRef.current.scale.set(8, 8, 2); // You can adjust the values as needed

//   return (
//       <group {...props} dispose={null}>

//         {/* <group position={[0.014, 0.046, -0.027]}  */}
//         {/* <group position={[-0.501, -0.346, 0.027]} */}
//         <group position={[-0.71, -0.546, 0.20]}


//           // rotation={[-1.45, -0.004, 0]}
//           rotation={[0, 0.0, 0.]}
//         >
//             <mesh ref={meshRef} scale={[10, 10, 4]} >

//           <mesh geometry={nodes.mesh_0.geometry} material={nodes.mesh_0.material} />
//           <mesh geometry={nodes.mesh_0_1.geometry} material={nodes.mesh_0_1.material} />
//           <mesh geometry={nodes.mesh_0_2.geometry} material={nodes.mesh_0_2.material} />
//           </mesh>
//  </group>  

//       </group>  

//   )
// }

// useGLTF.preload('vt7/vtseven.gltf')


/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.10 .\vt7_main_final.gltf 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Model(props) {
  const { nodes } = useGLTF('vt7/vt7_main_final.gltf');
  const meshRef = useRef();

  return (
    <group {...props} dispose={null}>
      <group
        position={[-0.71, -0.546, 0.20]}
        // rotation={[0, 0, 0.]}
      >

        <mesh ref={meshRef} scale={[10, 10, 4]} >

          <mesh geometry={nodes.mesh_0.geometry} material={nodes.mesh_0.material} />
          <mesh geometry={nodes.mesh_0_1.geometry} material={nodes.mesh_0_1.material} />
          <mesh geometry={nodes.mesh_0_2.geometry} material={nodes.mesh_0_2.material} />
        </mesh>
      </group>
    </group>
  )
}

useGLTF.preload('vt7/vt7_main_final.gltf')
