import React from 'react'
import HomeImg from '../assets/images/home_oneweigh.png';
import SettingImg from '../assets/images/settings_oneweigh.png';
import CalibrateImg from '../assets/images/calibrate_oneweigh.png';
import HistoryImg from '../assets/images/history_oneweigh.png';
import WheelLoader from '../assets/images/wheel_loader.png';
import ForkLift from '../assets/images/icon_forklift.png';

const Features = () => {
    const workInfoData = [
        {
            image: HomeImg,
            title: 'Interactive Home',
            text: "Home section is the main section of OneWeigh app. Here user can record their " +
                "work data while getting real-time attachment weight of their heavy vehicle " +
                "equipment. Equipment needs to be calibrated to use this section.",

        },
        {
            image: CalibrateImg,
            title: "Simplified Calibration",
            text: "Calibration section needs password entry where only admins who have set the " +
                "password can access it. Once valid password is entered, user will be taken " +
                "to calibration section to calibrate their machine."
        },
        {
            image: HistoryImg,
            title: 'Accessible History',
            text: "The history section contains work records of user. It will show accumulated " +
                " weight, target weight, start date and end date with time. Also, admins have " +
                " ability to delete work records from the delete icon button. "
        },
        {
            image: SettingImg,
            title: "Setting Screen",
            text: "The settings section provides user multiple features to configure " +
                "their device. It  also incorporates diagnostics section."
        },
    ]
    return (
        <div id='features' className='work-section-wrapper'>
            <p className='primary-subheading'>OneWeigh Features</p>
            <div className="container">
            {/* <p className='primary-subheading'>OneWeigh Features</p> */}
            {/* </br></br> */}
  <div className="leftDiv">
                    {/* Add your image here */}
                    <img src={WheelLoader} alt="" />
                </div>
                {/* <div
                 className='work-section-top'
                 > */}

                <div className="middleDiv">
                    {/* <h1 className='primary-heading'>How it Works</h1> */}
                    <p className='secondary-text'>
                        OneWeigh Scale Solutions is an advanced platform that provides real-time access to scale
                        weight data and ensures accurate data storage. It includes a rugged tablet equipped with
                        an application for rendering critical information and utilizes sensors for precise weight calculations.
                        This system enhances productivity, offers remote support, and enables efficient oversight of operations by management.

                    </p>
                    {/* <img src={WheelLoader} alt="" /> */}

                </div>
                <div className="rightDiv">
                    {/* Add another image or any content you want */}
                    <img src={ForkLift} alt="" />
                </div>
            </div>
            <div className='work-section-bottom'>
                {
                    workInfoData.map((data) => (
                        <div className='work-section-info'>
                            <div className='info-boxes-img-container'>
                                <img src={data.image} alt="" />
                            </div>
                            <h3 style={{ marginTop: '20px' }}>{data.title}</h3>
                            <p>{data.text}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Features