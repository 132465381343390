import React from 'react'
import AboutBackground from '../assets/images/vt7-final.png'
import AboutBackgroundImage from '../assets/images/about-background.png'
import { BsFillPlayCircleFill } from 'react-icons/bs'
import { Link } from 'react-scroll';

function About() {
    const sections = [
        'Home Section',
        'Calibration Section',
        'Settings Section',
        'History Section',
        'Help Section',
    ];
    return (
        <div id='about' className='about-section-container'>
            <div className='about-background-image-container'>
                <img src={AboutBackgroundImage} alt="About Background" />
            </div>
            <div className='about-section-image-container'>
                <img src={AboutBackground} alt="One Weigh Home Page" />
            </div>
            <div className='about-section-text-container'>
                {/* <p className='primary-subheading'>About</p> */}
                <p className='primary-heading'>
                    OneWeigh app includes mainly following functionality;
                </p>
                <p className='secondary-text'>
                    <ul>
                        {sections.map((section, index) => (
                            <li key={index}>{section}</li>
                        ))}
                    </ul>
                </p>
                <p className='secondary-text'>
                    By leveraging this advanced system, users can significantly enhance their productivity
                    while maintaining precision. The incorporation of remote diagnostics and remote
                    calibration functionality provides users with vital support, while seamless remote data
                    access empowers management and supervisory teams to efficiently oversee
                    operations.
                </p>
                <div className='about-buttons-container'>
                <Link to='features' smooth={true} duration={500}>
                <button className='secondary-button'>Learn More</button>
                </Link>
                    <button className='watch-video-button'>
                        <BsFillPlayCircleFill /> Watch Video
                    </button>
                </div>
            </div>
        </div>
    )
}

export default About