import React from 'react'
import BannerBackground from '../assets/images/home-banner-background.png';
import BannerImage from '../assets/images/vt7.png';
import { FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-scroll';


function Home() {
    return (
        <div id='home' className='home-container'>
            <div className='home-banner-container'>
                <div className='home-bannerImage-container'>
                    <img src={BannerBackground} alt='' />
                </div>
                <div className="home-text-section">
                    <p className='primary-heading'>
                        Trucktronix proudly presents, OneWeigh Scale Solutions.
                        {/* OneWeigh Scale Solutions offers a state-of-the-art platform that enables users to
                        access real-time scale weight data and ensure accurate data storage.  */}
                    </p>
                    <p className='secondary-text'>
                        OneWeigh Scale Solutions offers a state-of-the-art platform that enables users to
                        access real-time scale weight data and ensure accurate data storage.

                    </p><br />
                    <Link to='pricing' smooth={true} duration={500}>
                        <button className='secondary-button'>
                            Purchase Now <FiArrowRight />
                        </button>
                    </Link>

                </div>
                <div className='home-image-container'>
                    <img src={BannerImage} alt='' />
                </div>
            </div>
        </div>
    )
}

export default Home