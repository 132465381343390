import './App.css';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Home from './components/Home';
import Features from './components/Features';
import GoToTopButton from './components/GoToTopButton';
import Navbar from './components/Navbar';
import Pricing from './components/Pricing';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={
            <>
              <Navbar />
              <Home />
              <About />
              <Features />
              <Pricing />
              <Contact />
              <Footer />
              <GoToTopButton />
            </>
          } />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;


