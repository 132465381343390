// src/GoToTopButton.js
import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { FiArrowUp } from 'react-icons/fi';
const GoToTopButton = () => {
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 500, // Scroll to top in 500 milliseconds
      smooth: 'easeInOutQuad', // Optional easing function
    });
  };

  return (
    <button className="go-to-top-button" onClick={scrollToTop}>
      Go to Top <FiArrowUp/>
    </button>
  );
};

export default GoToTopButton;
