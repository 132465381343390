import React, { useState } from 'react';
import {  Col, Row } from 'react-bootstrap';
import Animation from './Animation'
import "./oneweigh.css"

function Pricing(props) {
    const [color] = useState("#80CED7");
    const description = "OneWeigh Scale Solutions offers a cutting-edge platform with a rugged tablet and an application to access real-time scale weight data accurately. The system utilizes sensors to provide essential information for precise weight calculations."
    const price = '$3999';
    const handleEmailClick = () => {
        // Handle email click here (e.g., open email client or show a contact form)
        const emailAddress = 'sales@haulweigh.com'; // Replace with the email address you want to use
        const subject = 'Inquiry'; // You can customize the subject of the email if needed

        // Create the mailto link
        const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}`;

        // Open the default email client with the mailto link
        window.location.href = mailtoLink;
    };
    return (
        <div
            style={{ margin: '5% 0%' ,
            // padding:'0 5%'
        }}
            id='pricing'>
            <Row className='item'>
                <Col sm={6}>
                    <Animation color={color} />
                </Col>
                <Col className='info'>
                    <p className='primary-subheading'>OneWeigh Scale</p>
                    {/* <br /> */}
                    <p className='secondary-text'>{description}</p>
                    <Row className='buttonRow'>

                        <Col style={{ textAlign: 'right' }}>
                            <p className='primary-text'>Starting Price: {price}</p>
                            {/* <a href={props.checkout_url.display}> */}
                            <button className='secondary-button' id="buy"
                                onClick={handleEmailClick}
                            >
                                Buy Now
                            </button>

                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>

    )
}
export default Pricing;