import React from 'react'

const Contact = () => {
    const handleEmailClick = () => {
        // Handle email click here (e.g., open email client or show a contact form)
        const emailAddress = 'sales@haulweigh.com'; // Replace with the email address you want to use
        const subject = 'Inquiry'; // You can customize the subject of the email if needed
    
        // Create the mailto link
        const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}`;
    
        // Open the default email client with the mailto link
        window.location.href = mailtoLink;
    };

    const handlePhoneClick = () => {
        // Handle phone click here (e.g., initiate a phone call)
        const phoneNumber = '1300 342 147'; // Replace with the phone number you want to use

    // Create the tel link
    const telLink = `tel:${phoneNumber}`;

    // Open the phone dialer with the tel link
    window.location.href = telLink;
    };
    return (
        <div id='contact' className='contact-page-wrapper'>
            <p className='primary-subheading'>
                Any Queries?
            </p>
            {/* <h1 className='primary-heading'>Let us help you</h1> */}
            {/* <div className='contact-form-container'>
                <input type='text' placeholder='yourmail@gmail.com' />
                <button className='secondary-button'>Submit</button>
            </div> */}
            <div className="call-to-action">
                <h3>Get in touch with us!</h3>
                <p>For inquiries, please contact us via email or phone.</p>
                <div className="cta-buttons">
                    <button className="cta-email" 
                    onClick={handleEmailClick}
                    >Email Us</button>
                    <button className="cta-phone" 
                    onClick={handlePhoneClick}
                    >Call Us</button>
                </div>
            </div>
        </div>
    )
}

export default Contact