import React, { useState } from 'react'
import Logo from "../assets/images/icon_trucktronix.png";
import { HiOutlineBars3 } from 'react-icons/hi2';
import {
    FeaturedPlayListRounded
}
    from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';
import { BsCurrencyDollar } from 'react-icons/bs';
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-scroll';

function Navbar() {
    const [openMenu, setOpenMenu] = useState(false);
    const menuOptions = [
        {
            text: "Home",
            icon: <HomeIcon />,
            link: 'home'
        },
        {
            text: "About",
            icon: <InfoIcon />,
            link: 'about'
        },
        {
            text: 'Features',
            icon: <FeaturedPlayListRounded />,
            link: 'features',
        },

        {
            text: 'Pricing',
            icon: <BsCurrencyDollar />,
            link: 'pricing'
        },
        {
            text: "Contact",
            icon: <PhoneRoundedIcon />,
            link: 'contact'
        },
    ]
    return (
        <>
            <nav>
                <div className='nav-logo-container'>
                    <img src={Logo} alt="logo" />
                </div>
                <div className='navbar-links-container'>

                    {/* <a href='#' > */}
                        <Link to='home' smooth={true} duration={500}>
                            Home
                        </Link>
                    {/* </a> */}
                    {/* <a href='#'> */}
                        <Link to='about' smooth={true} duration={500}>
                            About
                        </Link>
                    {/* </a> */}
                    <Link to='features' smooth={true} duration={500}>
                        {/* <a href='#'> */}
                            Features
                            {/* </a> */}
                    </Link>
                    <Link to='pricing' smooth={true} duration={500}>
                        {/* <a href=''> */}
                            Pricing
                            {/* </a> */}
                    </Link>
                    {/* <a href=''>Testimonials</a> */}
                    {/* <a href='#'> */}
                        <Link to='contact' smooth={true} duration={500}>
                            Contact
                        </Link>
                    {/* </a> */}
                    {/* <a href=''>
                    <BsCart2 className='navbar-cart-icon' />
                </a> */}
                    {/* <button className='primary-button'>Purchase Now</button> */}
                </div>
                <div className='navbar-menu-container'>
                    <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
                </div>
                <Drawer open={openMenu} onClose={() => { setOpenMenu(false) }}
                    anchor='right'>
                    <Box
                        sx={{ width: 230 }}
                        role='presentation'
                        onClick={() => setOpenMenu(false)}
                        onKeyDown={() => setOpenMenu(false)}
                    >
                        <List>
                            {menuOptions.map((item) => (
                                <ListItem key={item.text}
                                    style={{
                                        // display: 'flex', 
                                        // gap: -10,
                                        // alignItems: 'center',
                                        margninTop: '60px',
                                        //  justifyContent: 'center',
                                    }} >
                                    {/* <div style={{
                                    display: 'flex', gap: -10,
                                    alignItems: 'center', justifyContent: 'center'
                                }}> */}
                                    <Link
                                        style={{
                                            display: 'flex', gap: -10,
                                            alignItems: 'center',
                                            margninTop: '30px',
                                            justifyContent: 'center',
                                        }}
                                        onClick={() => setOpenMenu(false)}

                                        to={item.link} smooth={true} duration={500}>
                                        <ListItemIcon style={{
                                            marginLeft: 30,
                                            marginRight: -10,
                                        }}>{item.icon}</ListItemIcon>
                                        <ListItemText style={{
                                            fontSize: '16px'
                                        }}
                                            primary={item.text} />
                                    </Link>
                                    {/* </div> */}
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Drawer>

            </nav >
            {/* <hr></hr> */}
        </>
    )
}

export default Navbar